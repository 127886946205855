.totop{
  &__wrapper{
    position: fixed;

    background: #00909F;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.23);
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__container{
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 10000;
    transition: all .2s ease;
    width: 40px;
    height: 40px;
  }

  &__container:hover{
    transform: scale(1.2);
    cursor: pointer;
  }

  &__img{
    width: 80%;
    transform: rotate(180deg)
  }
}