@import '../../config.scss';

.GDPR{
  padding: 10px;
  position: fixed;
  left: 20px;
  bottom: 20px;
  width: 300px;
  background-color: #00909F;
  border-radius: 10px;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 20px #888888;


  &__text{
    color: white;
    text-align: center;
    font-family: $titleFont;
    font-size: 15px;
  }
}