
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
}



#root{
height: 100vh;
}





.row{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.col{
  display: flex;
  padding: 20px auto;
  flex-direction: column;
}

.col-sm-12{
  width: 100%;
}

.row-sm-reverse{
  flex-direction: column-reverse;
}


@media(min-width: 600px){
  .col-md-6{
    width: 50%
  }

  .col-md-8{
    width: 66.666667%
  }

  .row-sm-reverse{
    flex-direction: row;
  }

}

@media(min-width: 900px){
  .col-lg-8{
    width: 66.666667%
  }

  .col-lg-4{
    width: 33.333333%
  }
}



